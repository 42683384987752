module controllers {
    export module master {
        interface IBankUpdateScope extends ng.IScope {
        }

        interface IBankUpdateParams extends ng.ui.IStateParamsService {
            bnkId: number;
        }

        export class bankUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'bankService',
                'authorisedDealerService'
            ];


            bnkId: number;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            bank: interfaces.master.IBankMaster;
      
            constructor(
                private $scope: IBankUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IBankUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lbankUpdateCtrlookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private bankService: interfaces.master.IBankService,
                public authorisedDealerService: interfaces.tariff.IAuthorisedDealerService
            ) {

                this.bnkId = $stateParams.bnkId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.LoadBank(this.bnkId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'bnk.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
                
            }

            loadAuthorisedDealer() {
                var deferred = this.$q.defer();

                if (!this.bank) {
                    deferred.resolve([]);
                    return deferred.promise;
                }

                this.authorisedDealerService.getDropdownList(this.bank.CountryId).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    deferred.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferred.resolve([]);
                });

                return deferred.promise;
            }

            LoadBank(Id : number)
            {
                return this.bankService.getBank().query({ Id: Id }, (data: interfaces.master.IBankMaster) => {

                    this.bank = data;

                    this.breadCrumbDesc = this.bank.CompanyCode;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        //this.gvwInvoices.data = this.payment.InvoiceList;
                        //this.gvwLC.data = this.payment.LetterOfCreditList;
                        //this.loadControls();
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            Close() {
                this.$state.go("^");
            }

            saveBank() {

                
                return this.bankService.save().save(this.bank, (data: interfaces.applicationcore.IMessageHandler) => {
                     this.generalService.displayMessageHandler(data);
                                   
                  
                 }, (errorResponse) => {
                     this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
 
                 }).$promise;
             };
 
              Save() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'bnk.update'
                 }, () => {
                     return this.saveBank().then((result) => {
                         this.generalService.displayMessageHandler(result);
                         if (!result.HasErrorMessage) {
                             this.$timeout(() => {
                                 this.bsLoadingOverlayService.wrap({
                                     referenceId: 'pay.update'
                                 }, () => {
                                     return this.LoadBank(this.bnkId);
                                 });
                             });
                         }
                     });
                 });
             }
 
             SaveClose() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'bnk.update'
                 }, () => {
                     return this.saveBank().then(() => {
                         this.Close();
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                         this.$anchorScroll("topAnchor");
                     });
                 });
             }

          
        };

        angular.module("app").controller("bankUpdateCtrl", controllers.master.bankUpdateCtrl);
    }
}
